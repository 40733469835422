import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CurrentDateService } from '../../../services/current-date.service';
import { startDay } from '../../../utilities/date';

/**
 * DateRange is a component that displays date ranges in a normalized way.
 * 
 * ## Usage
 * ``` html
 * <date-range
 * [from]="date_from"
 * [to]="date_to"></date-range>
 * ```
 *
 * ### Related UI components:
 * - [[DateMdComponent|date-md]]
 */
@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeComponent implements OnInit {

  @Input() set from(v) {
    this._form = this.parseDate(v);
  };

  get from(): Date {
    return this._form;
  }

  @Input() set to(v) {
    this._to = this.parseDate(v);
  };

  get to(): Date {
    return this._to;
  }

  /** 
   * If true, the component will highlight if the date range is spot.
   */
  @Input() public showSpot: boolean;
  /** 
   * If true, the component will highlight if the date range is expired or
   * about to expire.
   */
  @Input() public styles: boolean = true;
  /** Displays a simpler range. */
  @Input() public simple: boolean;

  /** The language currently used. */
  public currentLang: string;
  /** @ignore */
  public nextWeek: Date;
  /** @ignore */
  public today: Date = this.currentDate.get();
  /** @ignore */
  public today_start: Date;

  private _form: Date;
  private _to: Date;

  /** @ignore */
  constructor(
    private currentDate: CurrentDateService,
    private translateService: TranslateService
  ) {
    this.today_start = startDay(this.today);

    this.nextWeek = new Date(this.today.getTime());
    this.nextWeek.setDate(this.today.getDate() + 7);
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }

  private parseDate(v: Date | string): Date {
    let response;

    if (typeof v === 'string') response = new Date(v);
    else response = v;

    return response;
  }
}
