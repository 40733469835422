import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { instanceToInstance } from 'class-transformer';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { ComponentCommService } from '../../../services/component-comm.service';
import { startDay } from '../../../utilities/date';
import { ColumnFiltersService } from '../../services/column-filters.service';

@Component({
  selector: 'column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnFilterComponent implements OnInit, OnDestroy {

  @ViewChild('filterDropdown') private readonly filterDropdown: BsDropdownDirective;

  @Input() public alignment: string = "right"; // Any other value will align to the left
  /** A selector specifying the element the popover should be appended to. */
  @Input() public container: string = '#agree-main';
  @Input() set type(value: string) {
    if (this.conditions[value]) { // Check for valid types
      this._type = value;
    }
  }
  /**
   * Temporary option
   * Adding dateRange makes the date range option visible
   * <column-filter [dateRange]="true" />
   */
  @Input() public dateRange: boolean;
  @Input() public dateRangeConfig: Partial<BsDatepickerConfig>;
  @Input() public disabledOptions: string[] = [];
  @Input() private key: string;
  @Input() public label: string;
  @Input() public canDelete: boolean = true;
  @Input() private options: { name: string, value: string }[];
  @Input() private filters: Params;

  @Output() readonly onChange = new EventEmitter();

  get type(): string {
    return this._type;
  }

  public autoClose: boolean = true;
  public conditions: any;
  public innerValue: ColumnData;
  public originalValue: any;
  public UUID: string;
  public dateRangeDefaultConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-green',
    dateInputFormat: "DD/MM/YYYY",
    selectWeek: true,
    adaptivePosition: true
  }

  private _type: string; // Setter and getter type value
  private subscriptions: Subscription[] = [];

  constructor(
    private componentComm: ComponentCommService,
    private columnFiltersService: ColumnFiltersService
  ) {
    this.UUID = 'cf-' + uuidv4();

    // Supported type and its conditions
    // Each condition value must be unique
    this.conditions = {
      custom: [],
      boolean: [
        { name: 'FILTERS.CONDITIONS.TRUE', value: 'true' },
        { name: 'FILTERS.CONDITIONS.FALSE', value: 'false' }
      ],
      date: [
        { name: 'MESSENGER.TODAY', value: 'today' },
        { name: 'FILTERS.CONDITIONS.LAST_WEEK', value: 'week' },
        { name: 'FILTERS.CONDITIONS.LAST_MONTH', value: 'month' },
        { name: 'FILTERS.CONDITIONS.WHEN', value: 'when' },
        { name: 'FILTERS.CONDITIONS.BEFORE', value: 'before' },
        { name: 'FILTERS.CONDITIONS.AFTER', value: 'after' },
      ],
      string: [
        { name: 'FILTERS.CONDITIONS.CONTAINS', value: 'contains' },
        { name: 'FILTERS.CONDITIONS.NOT', value: 'not' },
        { name: 'FILTERS.CONDITIONS.STARTS', value: 'starts' },
        { name: 'FILTERS.CONDITIONS.ENDS', value: 'ends' },
        { name: 'FILTERS.CONDITIONS.IS', value: 'is' }
      ],
      number: [
        { name: 'FILTERS.CONDITIONS.EQUAL', value: 'equal' },
        { name: 'FILTERS.CONDITIONS.GREATER_THAN', value: 'greater' },
        { name: 'FILTERS.CONDITIONS.LOWER_THAN', value: 'lower' }
      ]
    };
  }

  ngOnInit(): void {
    /**
      * Temporary validation
      * Add the date range option if dateRange is true, this is because 
      * the back does not contemplate this filter in all tables.
    */
    if (this.dateRange) {
      this.conditions.date.push({ name: 'FILTERS.CONDITIONS.RANGE', value: 'between' });

      this.dateRangeDefaultConfig = { ...this.dateRangeDefaultConfig, ...this.dateRangeConfig };
    }

    this.subscriptions.push(this.componentComm.events.subscribe(event => {
      // Hack to prevent multiple filters to be opened, consecuence of using clickStop
      if (event.name === 'dropdown-shown' &&
        event.id !== this.UUID &&
        this.filterDropdown) this.filterDropdown.hide();
    }));

    if (this.options) this.conditions.custom = this.options;

    this.setInnerValue();

    this.columnFiltersService.add({
      key: this.key,
      label: this.label,
      canDelete: this.canDelete
    });
  }

  public filterOptions(options: { name: string, value: string }[]): { name: string; value: string; }[] {
    return options.filter(opt => !this.disabledOptions.includes(opt.value));
  }

  public focus(delay: number = 0): void {
    setTimeout(() => {
      const input = document.getElementById('input-' + this.UUID);
      if (input) input.focus();
    }, delay);
  }

  /** Sets the original condition and value. */
  private parseConditionValue(d: ColumnData): void {
    if (d.source) {
      const keyValue = d.source.split(':');

      d.condition = (this.type === 'custom') ? d.source : keyValue[0];
      d.value = this.type === 'date' ? this.handleDate(d) : keyValue[1];
    } else {
      d.condition = undefined;
      d.value = undefined;
    }
  }

  private handleDate(d: ColumnData): Date | Date[] {
    // Handle range of dates
    if (d.source.includes('between:')) {
      let parsedDate = this.parseDateRangeValue(d.source.slice(8));
      d.source = parsedDate as any;
      return parsedDate;
    }

    return new Date(d.source.substring(d.source.indexOf(':') + 1));
  }

  private parseDateRangeValue(value: any): Date[] {
    if (!value) return null;
    const range = !Array.isArray(value) ? value.split(',') : value;
    if (range[0] == 'today') {
      range[0] = new Date();

      const days = parseInt(range[1]);

      range[1] = new Date();
      range[1].setDate(range[1].getDate() + days);
    }

    const from = startDay(new Date(range[0])); // start of the day
    const to = new Date(range[1]);

    to.setHours(23, 59, 59, 999); // end of the day

    const parsedRange = [from, to];

    return parsedRange;
  }

  private setInnerValue(): void {
    let data: ColumnData = new ColumnData();
    if (this.key && this.filters) {
      data.key = this.key;
      data.source = this.filters[this.key];

      this.parseConditionValue(data);
    }

    this.innerValue = instanceToInstance(data);
  }

  public onHidden(): void {
    this.parseConditionValue(this.innerValue);
  }

  public onShown(): void {
    // Hack to prevent multiple filters to be opened, consecuence of using clickStop
    this.componentComm.emit({ name: 'dropdown-shown', id: this.UUID });
    this.focus();
  }

  public onSubmit(): void {
    let newParam = {
      key: this.innerValue.key,
      value: this.innerValue.condition
    }

    if (this.innerValue.value instanceof Date) {
      // Dates must be parsed
      newParam.value += ':' + this.innerValue.value.toISOString();
    } else if (this.innerValue.value) {
      newParam.value += ':' + this.innerValue.value;
    }

    this.innerValue.source = newParam.value;
    this.filterDropdown.hide();
    this.onChange.emit(newParam);
  }

  public removeFilter(): void {
    this.onChange.emit({
      key: this.innerValue.key,
      value: null
    });
    this.innerValue.source = undefined;
    this.filterDropdown.hide();
  }

  public onShowDatepicker(): void {
    // Temporarily disable autoClose
    this.autoClose = false;
  }

  public onHiddenDatepicker(): void {
    // Enable autoClose
    setTimeout(() => {
      this.autoClose = true;
    });
  }

  /**
   * Handles changes to the range picker and updates the internal value.
   *
   * @param {Date[]} dates - The selected date range.
   */
  public onRangePickerChange(dates: Date[]): void {
    const from = new Date(dates[0]);
    const to = new Date(dates[1]);

    // Set end date to the end of the day
    to.setHours(23, 59, 59, 999);

    this.innerValue.source = dates;
    this.innerValue.value = `${from.toISOString()},${to.toISOString()}`;
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.columnFiltersService.delete({ key: this.key });

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

class ColumnData {
  key: string;
  condition: string = undefined;
  value: string | Date[] | Date;
  source: any;
}