import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClassConstructor, instanceToInstance, instanceToPlain, plainToInstance } from "class-transformer";
import { PluckPipe } from 'ngx-pipes';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap, mergeWith, shareReplay, startWith } from 'rxjs/operators';

import { AuctionHistory } from '../../../../models/auction-history.model';
import { Company } from '../../../../models/company.model';
import { DeliveryTypeGroup } from '../../../../models/delivery-type-group.model';
import { DeliveryType } from '../../../../models/delivery-type.model';
import { GroupBy } from '../../../../models/group-by.model';
import { PaymentCondition } from '../../../../models/payment-condition.model';
import { Product } from '../../../../models/product.model';
import { CurrentDateService } from '../../../../services/current-date.service';
import { DataDogLoggerService } from '../../../../services/data-dog-logger.service';
import { IntercomService } from '../../../../services/intercom.service';
import { PusherService } from '../../../../services/pusher.service';
import { constructFormData } from "../../../../utilities/construct-form-data";
import { buildFilters } from '../../../../utilities/filters';
import { simpleHash } from '../../../../utilities/json-tools';
import { Contract } from '../../contracts/models/contract.model';
import { ContractClauseGroup } from '../../imported-data/models/contract-clause-group.model';
import { Negotiation } from '../models/negotiation.model';
import { Order } from '../models/order.model';
import { Proposal } from '../models/proposal.model';

@Injectable()
export class OrderService {

  private marketUrl = '/:apiBase/markets/:marketId';
  private paymentConditionsUrl = '/:apiBase/payment-conditions';
  private quantityTypesUrl = '/:apiBase/quantity-types';
  private extraAttributesUrl = '/:apiBase/orders/extra-attributes/:slug';

  private companyUrl = '/:apiBase/companies/:companyId';
  private productsUrl = this.companyUrl + '/products';
  // private contractTranslateUrl = this.productsUrl + '/:productId/contract-clauses';
  private qualityTranslateUrl = this.productsUrl + '/:productId/quality';
  private deliveryTypesUrl = this.marketUrl + '/orders-delivery-types';
  private ordersUrl = this.companyUrl + '/orders';
  private orderUrl = this.ordersUrl + '/:orderId';
  private workingOrdersUrl = this.companyUrl + '/orders-working';
  private workingOrdersByMarketUrl = this.marketUrl + '/orders-working';
  private templatesUrl = this.companyUrl + '/templates';
  private orderNegotiationsUrl = this.orderUrl + '/negotiations';
  private orderRepresentedUrl = this.orderUrl + '/represented';
  private orderBrokerUrl = this.orderUrl + '/broker';
  // private ownerBehindBrokerUrl = this.negotiationsUrl + '/represented/:brokerId';
  private operatingOrdersUrl = this.companyUrl + '/orders-operating';
  private tradedNegotiationsUrl = this.companyUrl + '/traded-orders';
  private companyTradedNegotiationsUrl = this.companyUrl + '/company-traded-orders';
  private tradedPrivateNegotiationsUrl = this.companyUrl + '/traded-orders/private';
  private companyNegotiationsUrl = this.companyUrl + '/negotiations';
  // TODO: The path of this resource do not follow the conventions
  private companyNegotiationsStatsUrl = this.companyUrl + '/negotiations-stats';
  private negotiationsUrl = this.companyNegotiationsUrl + '/:negotiationId';
  private negotiationRepresentedUrl = this.negotiationsUrl + '/represented';
  private negotiationBrokerUrl = this.negotiationsUrl + '/broker';
  private suggestedNegotiationsUrl = this.negotiationsUrl + '/suggestion-to-link';
  private linkNegotiationsUrl = this.negotiationsUrl + '/link';
  private bookingDateUrl = this.negotiationsUrl + '/original-booking-date';
  private republishOrdersUrl = this.companyUrl + '/orders-republish'
  private datetoRepublishUrl = this.companyUrl + '/orders-republish-date'
  private preOrdersUrl = this.companyUrl + '/pre-orders';
  private ordersValidationUrl = this.ordersUrl + '/validation';
  private ordersMassiveBookUrl = this.ordersUrl + '/negotiations';
  private adminOrdersByMarket = '/:apiBase/admin/markets/:marketId/orders';
  private deleteTemplateUrl = this.companyUrl + '/templates/:templateId';
  private enrollToAuctionUrl = this.orderUrl + '/auction-enroll';
  private auctionUrl = this.orderUrl + '/auction';
  // private getEnrolledCompaniesUrl = this.orderUrl + '/auction-enrolled-companies';
  private accountsNegotiationAssigneesUrl = this.negotiationsUrl + '/assignee';
  private accountsOrderAssigneesUrl = this.orderUrl + '/assignee';
  private mediaUrl = this.orderUrl + '/media/:mediaId';
  private contractTranslateUrl = this.companyUrl + '/translate/contract-clauses';
  private negotiationsExportUrl = this.companyUrl + '/negotiations-export';
  // TODO: The path of this resource do not follow the conventions
  private negotiationRelatedContractsPath = this.negotiationsUrl + '/contracts-to-link';

  private _nCollectionSubjects: { [eventKey: string]: BehaviorSubject<{ body: Negotiation[]; headers: HttpHeaders }> } = {};
  private _pCollectionSubjects: { [eventKey: string]: BehaviorSubject<any> } = {};
  private _wCollectionSubjects: { [eventKey: string]: BehaviorSubject<GroupBy<Product, Order>[]> } = {};
  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product][id]',
    'zone_id': 'filters[business_detail][delivery][locations][zone][][id]',
    'location_id': 'filters[business_detail][delivery][locations][location][][id]',
    'operation_type': 'filters[operation_type]',
    'status_group_orders': 'filters[status_group_orders]',
    'delivery_type': 'filters[delivery_type]',
    'order_type': 'filters[order_type]',
    'range': 'filters[range]',
    'past_range': 'filters[range]',
    'payment_condition': 'filters[payment_condition]',
    'company_name': 'filters[company_name]',
    'broker_name': 'filters[broker_name]',
    'seller_name': 'filters[seller_name]',
    'buyer_name': 'filters[buyer_name]',
    'validity': 'filters[validity]',
    'price': 'filters[price]',
    'has_contract': 'filters[has_contract]',
    'label_id': 'filters[label_id]',
    'visible_to': 'filters[visible_to]',
    'crop': 'filters[crop]',
    'sustainable': 'filters[sustainable]',
    'delivery_range': 'filters[delivery_range]'
  };

  constructor(
    private http: HttpClient,
    private pusherService: PusherService,
    private intercomService: IntercomService,
    private currentDate: CurrentDateService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  // getContractTranslate(companyId : number, productId: number, lang: string): Observable<any[]> {

  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.append('Accept-Language', lang);

  //   let url = this.contractTranslateUrl.replace(':companyId', companyId + '').replace(':productId', productId + '');
  //   return this.http.get<any[]>(url, {headers});
  // }

  // private isOrder(entity: Order | Proposal): boolean {
  //   return (entity as Order).company !== undefined;
  // }

  public translateContract(companyId: number, entities: {
    order: Order,
    negotiation?: Negotiation
  }, contract: ContractClauseGroup[], language: string): Observable<any> {
    const url = this.contractTranslateUrl.replace(':companyId', companyId + ''),
      payload = {
        contract: contract,
        order: entities.order,
        negotiation: entities.negotiation
      };

    return this.http.post<any>(url + '/' + language, payload);
  };

  public getQualityTranslate(companyId: number, productId: number, lang: string): Observable<any[]> {

    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Accept-Language', lang);

    const url = this.qualityTranslateUrl.replace(':companyId', companyId + '').replace(':productId', productId + '');
    return this.http.get<any[]>(url, { headers });
  }

  public getDeliveryTypes(marketId: number): Observable<GroupBy<DeliveryTypeGroup, DeliveryType>[]> {
    const stream = this.http.get<DeliveryType[]>(this.deliveryTypesUrl.replace(':marketId', marketId + ''))

    return stream.pipe(map(groups => {
      return this.tranformToClass(groups, DeliveryTypeGroup, DeliveryType);
    }));
  }

  public getPaymentConditions(): Observable<PaymentCondition[]> {
    return this.http.get<PaymentCondition[]>(this.paymentConditionsUrl)
  }

  public getQuantityTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.quantityTypesUrl)
  }

  public getExtraAttributes<T>(slug: string): Observable<any[]> {
    const url = this.extraAttributesUrl
      .replace(':slug', slug);

    return this.http.get<T[]>(url);
  }

  public getOrder(companyId: number, orderId: number): Observable<Order> {
    const url = this.orderUrl.replace(':companyId', companyId + '').replace(':orderId', orderId + '');

    const stream = this.http.get<Order>(url);

    return stream.pipe(map(order => {
      return plainToInstance(Order, order);
    }));
  }

  public watchOrder(companyId: number, orderId: number, eager = true): Observable<Order> {
    return this.pusherService.listen('public', 'order').pipe(
      mergeWith(this.pusherService.listen('company_' + companyId, 'negotiation')),
      startWith(this, eager ? [{}] : []),
      mergeMap(order => {
        return this.getOrder(companyId, orderId);
      })
    );
  }

  public watchAuction(companyId: number, orderId: number): Observable<AuctionHistory> {
    return this.pusherService.listen('public', 'auction', 0).pipe(
      startWith({}),
      mergeMap(auction => {
        return this.getAuction(companyId, orderId);
      })
    );
  }

  public watchAuctionMoments(orderId: number): Observable<any> {
    return this.pusherService.listen('public', 'auction-moment', 0).pipe(
      mergeMap(response => {
        if (orderId === response.data.id) {
          return of(response);
        } else {
          return of(null);
        }
      })
    );
  }

  private getAuction(companyId: number, orderId: number): Observable<AuctionHistory> {
    const url = this.auctionUrl.replace(':companyId', companyId + '').replace(':orderId', orderId + '');

    return this.http.get<AuctionHistory>(url).pipe(
      map(auction => {
        return plainToInstance(AuctionHistory, auction);
      })
    );
  }

  public placeBid(companyId: number, orderId: number, value: number, limit_value?: number): Observable<any> {
    const url = this.auctionUrl.replace(':companyId', companyId + '').replace(':orderId', orderId + '');

    const data = {
      value: value,
      limit_value: null
    };

    if (limit_value) {
      data.limit_value = limit_value;
    }

    return this.http.post<any>(url, data);
  }

  public cancelAutomaticAuction(companyId: number, orderId: number): Observable<any> {
    const url = this.auctionUrl.replace(':companyId', companyId + '').replace(':orderId', orderId + '');

    return this.http.delete<any>(url, {});
  }

  public getNegotiation(companyId: number, negotiationId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    return this.http.get<Negotiation>(url).pipe(
      map(negotiation => {
        return plainToInstance(Negotiation, negotiation);
      })
    );
  }

  public watchNegotiation(companyId: number, negotiationId: number): Observable<Negotiation> {
    return this.pusherService.listen('company_' + companyId, 'negotiation').pipe(
      startWith({}),
      mergeMap(negotiation => {
        return this.getNegotiation(companyId, negotiationId);
      })
    );
  }

  public geAdmintWorkingOrdersByMarket(marketId: number): Observable<GroupBy<Product, Order>[]> {
    const url = this.adminOrdersByMarket.replace(':marketId', marketId + '');
    return this.doOrdersRequest(url);
  }

  public getWorkingOrders(companyId: number, filters?: any): Observable<GroupBy<Product, Order>[]> {
    let url = this.workingOrdersUrl.replace(':companyId', companyId + '');
    url = buildFilters(url, filters, this.queryMap);

    return this.doOrdersRequest(url);
  }

  private getWorkingOrdersByMarket(marketId: number, filters?: any): Observable<GroupBy<Product, Order>[]> {
    let url = this.workingOrdersByMarketUrl.replace(':marketId', marketId + '');
    url = buildFilters(url, filters, this.queryMap);

    return this.doOrdersRequest(url);
  }

  public getTemplates(companyId: number, filters?: any): Observable<GroupBy<Product, Order>[]> {
    let url = this.templatesUrl.replace(':companyId', companyId + '');
    url = buildFilters(url, filters, this.queryMap);

    return this.doOrdersRequest(url);
  }

  public getDateToRepublish(companyId: number): Observable<any> {
    const url = this.datetoRepublishUrl
      .replace(':companyId', companyId + '');

    return this.http.get(url);
  }

  public getOrdersToRepublish(companyId: number, date?: string): Observable<GroupBy<Product, Order>[]> {
    let url = this.republishOrdersUrl.replace(':companyId', companyId + '');

    if (date)
      url += "?date=" + date;

    return this.doOrdersRequest(url);
  }

  public republish(companyId: number, ordersSelected): Observable<any> {
    const url = this.republishOrdersUrl.replace(':companyId', companyId + '');;

    return this.http.post(url, ordersSelected);

  }

  public watchWorkingOrders(companyId: number, filters?: any): Observable<GroupBy<Product, Order>[]> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._wCollectionSubjects,
        key: eventKey,
        getData: () => this.getWorkingOrders(companyId, filters)
      },
      {
        channel: 'public',
        event: 'order'
      }
    );
  }

  public watchWorkingOrdersByMarket(marketId: number, filters?: any): Observable<GroupBy<Product, Order>[]> {
    return this.pusherService.listen('public', 'order').pipe(
      startWith({}),
      mergeMap(event => {
        return this.getWorkingOrdersByMarket(marketId, filters);
      })
    );
  }

  public watchTemplates(companyId: number, filters?: any): Observable<GroupBy<Product, Order>[]> {
    return this.pusherService.listen('company_' + companyId, 'template').pipe(
      startWith({}),
      mergeMap(event => {
        return this.getTemplates(companyId, filters);
      })
    );
  }

  public getTradedNegotiations(companyId: number, filters?: any): Observable<HttpResponse<GroupBy<Product, Negotiation>[]>> {
    // TODO: posible refactorizacion junto al metodo getCompanyTradedNegotiations() por la similitud del codigo
    let url = this.tradedNegotiationsUrl.replace(':companyId', companyId + '');

    if (!filters?.page) filters = { ...filters, page: 1 };

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<GroupBy<Product, Negotiation>[]>(url, { observe: 'response' });
    return stream.pipe(map(response => {
      for (let i in response.body) {
        response.body[i].key = plainToInstance(Product, response.body[i].key);
        response.body[i].values = plainToInstance(Negotiation, response.body[i].values);
      }
      return response;
    }));
  }

  public watchTradedOrders(companyId: number, filters?: any): Observable<HttpResponse<GroupBy<Product, Negotiation>[]>> {
    return this.pusherService.listen('public', 'negotiation').pipe(
      startWith({}),
      mergeMap(event => {
        return this.getTradedNegotiations(companyId, filters);
      })
    );
  }

  private getTradedPrivateNegotiations(companyId: number, filters?: any): Observable<any> {
    let url = this.tradedPrivateNegotiationsUrl.replace(':companyId', companyId + '');

    url = buildFilters(url, filters, this.queryMap);

    return this.http.get(url);
  }

  public watchTradedPrivateNegotiations(companyId: number, filters?: any): Observable<any> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._pCollectionSubjects,
        key: eventKey,
        getData: () => this.getTradedPrivateNegotiations(companyId, filters)
      },
      {
        channel: 'public',
        event: 'negotiation'
      }
    );
  }

  /** @deprecated */
  private getCompanyTradedNegotiations(companyId: number, filters?: any): Observable<HttpResponse<GroupBy<Product, Negotiation>[]>> {
    // TODO: posible refactorizacion junto al metodo getTradedNegotiations() por la similitud del codigo
    let url = this.companyTradedNegotiationsUrl.replace(':companyId', companyId + '');

    if (!filters?.page) filters = { ...filters, page: 1 };

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<GroupBy<Product, Negotiation>[]>(url, { observe: 'response' });
    return stream.pipe(map(response => {
      for (let i in response.body) {
        response.body[i].key = plainToInstance(Product, response.body[i].key);
        response.body[i].values = plainToInstance(Negotiation, response.body[i].values);
      }
      return response;
    }));
  }

  /** @deprecated */
  public watchCompanyTradedNegotiations(companyId: number, filters?: any): Observable<HttpResponse<GroupBy<Product, Negotiation>[]>> {
    return this.pusherService.listen('company_' + companyId, 'negotiation').pipe(
      startWith({}),
      mergeMap(event => {
        return this.getCompanyTradedNegotiations(companyId, filters);
      })
    );
  }

  public getCompanyNegotiations(companyId: number, filters?: any): Observable<{ body: Negotiation[], headers: HttpHeaders }> {
    let url = this.companyNegotiationsUrl.replace(':companyId', companyId + '');

    if (!filters?.page) filters = { ...filters, page: 1 };

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<Negotiation[]>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(Negotiation, response.body), headers: response.headers };
    }));
  }

  public getCompanyNegotiationsStats(companyId: number, filters?: any): Observable<any> {
    let url = this.companyNegotiationsStatsUrl.replace(':companyId', companyId + '');
    url = buildFilters(url, filters, this.queryMap);

    return this.http.get(url);
  }

  public watchCompanyNegotiations(companyId: number, filters?: any): Observable<{ body: Negotiation[], headers: HttpHeaders }> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._nCollectionSubjects,
        key: eventKey,
        getData: () => this.getCompanyNegotiations(companyId, filters)
      },
      {
        channel: `company_${companyId}`,
        event: 'negotiation'
      }
    );
  }

  public getOperatingOrders(companyId: number, filters?: any): Observable<HttpResponse<GroupBy<Product, Order>[]>> {
    let url = this.operatingOrdersUrl.replace(':companyId', companyId + '');

    if (!filters?.page) filters = { ...filters, page: 1 };

    url = buildFilters(url, filters, this.queryMap);

    return this.doOrdersRequestResponseHeaders(url);
  }

  public watchOperatingOrders(companyId: number, filters?: any): Observable<HttpResponse<GroupBy<Product, Order>[]>> {
    return this.pusherService.listen('company_' + companyId, 'negotiation').pipe(
      mergeWith(this.pusherService.listen('company_' + companyId, 'order')),
      startWith({}),
      mergeMap(event => {
        return this.getOperatingOrders(companyId, filters);
      }),
      shareReplay(1)
    );
  }

  public getPreOrders(companyId: number, filters?: any, heavy_list: boolean = false): Observable<GroupBy<Product, Order>[]> {
    let url = this.preOrdersUrl.replace(':companyId', companyId + '');

    url = buildFilters(url, filters, this.queryMap);

    return this.doOrdersRequest(url);
  }

  public watchPreOrders(companyId: number, filters?: any, heavy_list: boolean = false): Observable<GroupBy<Product, Order>[]> {
    return this.pusherService.listen('company_' + companyId, 'order').pipe(
      startWith({}),
      mergeMap(event => {
        return this.getPreOrders(companyId, filters, heavy_list);
      })
    );
  }

  /** Is this still necessary? */
  private tranformToClass(groups: any[], GroupClass: ClassConstructor<any>, ValuesClass: ClassConstructor<any>): GroupBy<any, any>[] {
    for (let i in groups) {
      // TODO: class-transoformer does not support generics yet.
      groups[i].key = plainToInstance(GroupClass, groups[i].key);
      groups[i].values = plainToInstance(ValuesClass, groups[i].values);
    }
    return groups;
  }

  private doOrdersRequest(url: string): Observable<GroupBy<Product, Order>[]> {
    const stream = this.http.get<GroupBy<Product, Order>[]>(url);
    return stream.pipe(map(groups => {
      return this.tranformToClass(groups, Product, Order);
    }));
  }

  private doOrdersRequestResponseHeaders(url: string): Observable<HttpResponse<GroupBy<Product, Order>[]>> {
    const stream = this.http.get<GroupBy<Product, Order>[]>(url, { observe: 'response' });
    return stream.pipe(map(response => {
      // La expresión regular es un workaround para salvar los casos donde el header Date viene con doble fecha
      // Ejemplo: Thu, 21 May 2020 16:20:57 GMT, Thu, 21 May 2020 16:20:57 GMT
      // Funciona tanto para los casos con doble fecha como para los de fecha simple
      this.currentDate.set(new Date(response.headers.get('date').match(/[^,]+,[^,]+/g)[0]));

      this.tranformToClass(response.body, Product, Order);
      return response;
    }));
  }

  public create(order: Order, companyId: number): Observable<Order> {
    const url = this.ordersUrl.replace(':companyId', companyId + '');
    const form_data = constructFormData(order);

    return this.http.post<Order>(url, form_data);
  }

  /** This seems unnecessary. */
  public cloneProposal(proposal: Proposal): Proposal {
    // Clonar proposal
    const clone: Proposal = instanceToInstance(proposal);

    // Asegurarse que no quede máxima (FAS-1006)
    // TODO: Mejorar, quantity type hardcodeado!
    clone.business_detail.quantity.type = {
      "id": 1,
      "name": "Fixed",
      "slug": "fixed"
    };

    return clone;
  }

  public book(order: Order, company: Company, quantity: number = 0): Observable<Order> {
    const url = this.orderNegotiationsUrl
      .replace(':companyId', company.id + '')
      .replace(':orderId', order.id + '');

    const negotiation = new Negotiation();
    negotiation.proposal = this.cloneProposal(order);

    if (order.operation_type === 'venta') {
      if (company.market.configuration.order.pre_book.sell == false) {
        // es orden de venta y no tiene pre-anote para la venta
        negotiation.status = { id: 7, name: null };
      } else {
        // es orden de venta y tiene pre-anote para la venta
        negotiation.status = { id: 3, name: null };
      }
    }

    if (order.operation_type === 'compra') {
      if (company.market.configuration.order.pre_book.buy == false) {
        // es orden de compra y no tiene pre-anote para la compra
        negotiation.status = { id: 7, name: null };
      } else {
        // es orden de compra y tiene pre-anote para la compra
        negotiation.status = { id: 3, name: null };
      }

      if (quantity) {
        negotiation.proposal.business_detail.quantity.value = quantity;
      }
    }

    const data = instanceToPlain(negotiation);

    // TODO: No hardcodear ids
    const stream = this.http.post<Order>(url, data);

    if (order.operation_type === 'venta') {
      this.intercomService.track('order-booked', {
        order_id: order.id,
        product_name: order.product.name
      });
    }

    return stream;
  }

  public massiveBook(selection: Order[], companyId: number): Observable<any> {
    const pluckPipe = new PluckPipe();
    const orders = pluckPipe.transform(selection, 'id');

    const url = this.ordersMassiveBookUrl.replace(':companyId', companyId + '');
    const data = {
      'status': {
        'id': 7
      },
      'orders': Array()
    }

    orders.forEach(id => {
      data.orders.push({ 'id': id });
    });

    const stream = this.http.post(url, data);

    selection.forEach(order => {
      this.intercomService.track('order-booked', {
        order_id: order.id,
        product_name: order.product.name
      });
    });

    return stream;
  }

  public requestDisclosure(order: Order, companyId: number, quantity: number = 0): Observable<Order> {
    const url = this.orderNegotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', order.id + '');

    const negotiation = new Negotiation();

    // No iniciada
    negotiation.status = { id: 18, name: null };

    // Solicitud de revelación enviada
    negotiation.status_disclosure = { id: 15, name: null };

    return this.http.post<Order>(url, instanceToPlain(negotiation));
  }

  private createCounterOrder(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.orderNegotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', negotiation.order.id + '');

    negotiation.status = { id: 1, name: null };

    const data = instanceToPlain(negotiation);

    const stream = this.http.post<Negotiation>(url, data).pipe(map(neg => {
      negotiation.id = neg.id;
      return negotiation;
    }));

    return stream;
  }

  public negotiationRequest(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.orderNegotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', negotiation.order.id + '');

    negotiation.status = { id: 12, name: null };

    const data = instanceToPlain(negotiation);

    const stream = this.http.post<Negotiation>(url, data).pipe(map(neg => {
      negotiation.id = neg.id;
      return negotiation;
    }));

    return stream;
  }

  public acceptNegotiationRequest(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiation.id + '');

    negotiation.status = { id: 13, name: null };

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    return stream;
  }

  public acceptCancelation(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiation.id + '');

    negotiation.status = { id: 22, name: null };

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    return stream;
  }

  public udpateNegotiationStatus(negotiation: Negotiation, companyId: number, statusId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiation.id + '');

    const tempNegotiation: Negotiation = instanceToInstance(negotiation);

    tempNegotiation.status = { id: statusId, name: null };

    return this.http.put<Negotiation>(url, tempNegotiation).pipe(map(response => {
      return tempNegotiation;
    }));
  }

  private updateCounterOrder(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiation.id + '');

    if (companyId === negotiation.company.id) {
      // Negotiation owner sends
      negotiation.status = { id: 1, name: null };
    } else {
      // Order owner sends
      negotiation.status = { id: 2, name: null };
    }

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    return stream;
  }

  public counterOrder(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    return negotiation.id ?
      this.updateCounterOrder(negotiation, companyId) :
      this.createCounterOrder(negotiation, companyId);
  }

  public acceptNegotiation(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiation.id + '');

    negotiation.status = { id: 7, name: null };

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    this.intercomService.track('order-booked', {
      order_id: negotiation.order.id,
      product_name: negotiation.order.product.name
    });

    return stream;
  }

  public rejectNegotiation(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiation.id + '');

    if (companyId !== negotiation.company.id && negotiation.status.id === 3) {
      negotiation.status = { id: 8, name: null };
    } else if (
      (companyId === negotiation.company.id && negotiation.status.id === 1) ||
      (companyId !== negotiation.company.id && negotiation.status.id === 2) ||
      (companyId === negotiation.company.id && negotiation.status.id === 3)
    ) {
      // Cancelled
      negotiation.status = { id: 5, name: null };
    } else {
      // Rejected
      negotiation.status = { id: 6, name: null };
    }

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    return stream;
  }

  public rejectNegotiationRequest(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiation.id + '');

    // Request negotiation rejected
    negotiation.status = { id: 14, name: null };

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    return stream;
  }

  public cancel(order: Order, companyId: number): Observable<Order> {
    const url = this.orderUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', order.id + '');

    const data = {
      'order_status': {
        'id': 4
      }
    };

    return this.http.post<Order>(url, data);
  }

  public reject(order: Order, companyId: number): Observable<Order> {
    const url = this.orderUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', order.id + '');

    const data = {
      'order_status': {
        'id': 3
      }
    };

    return this.http.post<Order>(url, data);
  }

  public preOrderMassiveAction(orders: Order[], companyId: number): Observable<Order> {
    const url = this.preOrdersUrl.replace(':companyId', companyId + '');

    return this.http.put<Order>(url, orders);
  }

  public ordersValidation(orders: Order[], companyId: number): Observable<HttpResponse<Order>> {
    const url = this.ordersValidationUrl.replace(':companyId', companyId + '');

    return this.http.post<Order>(url, { 'pre_orders': orders }, { observe: 'response' });
  }

  public onHold(order: Order, companyId: number, status: number): Observable<Order> {
    const url = this.orderUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', order.id + '');

    const data = {
      'on_hold': status
    };

    return this.http.post<Order>(url, data);
  }

  public save(order: Order, companyId: number): Observable<Order> {
    const url = this.orderUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', order.id + '');

    const form_data = constructFormData(order);

    return this.http.post<Order>(url, form_data);
  }

  public deleteTemplate(companyId, templateId): Observable<any> {
    const url = this.deleteTemplateUrl.replace(':companyId', companyId + '').replace(':templateId', templateId + '');
    const stream = this.http.delete<any>(url);

    stream.subscribe({
      next: response => { },
      error: error => {
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });

    return stream;
  }

  public enrollToAuction(companyId, order: Order): Observable<any> {
    const url = this.enrollToAuctionUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', order.id + '');

    return this.http.post<any>(url, {});
  }

  public saveAssigneeOrder(orderId: number, companyId: number, accountsAssigness: any): Observable<any> {
    const url = this.accountsOrderAssigneesUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', orderId + '');

    return this.http.post<any>(url, accountsAssigness);
  }

  public saveAssigneeNegotiation(negotiationId: number, companyId: number, accountsAssigness: any): Observable<any> {
    const url = this.accountsNegotiationAssigneesUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    return this.http.post<any>(url, accountsAssigness);
  }

  /** TODO: Unused */
  // private getAccountsAssignedOrders(orderId: number, companyId: number, role_id: number) {
  //   const url = this.accountsOrderAssigneesUrl
  //     .replace(':companyId', companyId + '')
  //     .replace(':orderId', orderId + '')
  //     + '?role_id=' + role_id;

  //   return this.http.get(url);
  // }

  /** TODO: Unused */
  // private getAccountsAssignedNegotiations(negotiationId: number, companyId: number, role_id: number) {
  //   const url = this.accountsNegotiationAssigneesUrl
  //     .replace(':companyId', companyId + '')
  //     .replace(':negotiationId', negotiationId + '')
  //     + '?role_id=' + role_id;

  //   return this.http.get(url);
  // }

  public deleteMedia(companyId: number, orderId: number, mediaId: string): Observable<any> {
    const url = this.mediaUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', orderId + '')
      .replace(':mediaId', mediaId + '');

    return this.http.delete(url);

  }

  public orderRepesented(companyId: number, orderId: number, companies: Company[]): Observable<any> {
    const url = this.orderRepresentedUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', orderId + '');

    return this.http.post(url, companies);
  }

  public negotiationRepesented(companyId: number, negotiationId: number, companies: Company[]): Observable<any> {
    const url = this.negotiationRepresentedUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    return this.http.post(url, companies);
  }

  public negotiationBroker(companyId: number, negotiationId: number, broker: Company): Observable<any> {
    const url = this.negotiationBrokerUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    const body = { id: broker.id };

    return this.http.post(url, body);
  }

  public orderBroker(companyId: number, orderId: number, broker: Company): Observable<any> {
    const url = this.orderBrokerUrl
      .replace(':companyId', companyId + '')
      .replace(':orderId', orderId + '');

    const body = { id: broker.id };

    return this.http.post(url, body);
  }

  public getSuggestNegotiation(companyId: number, negotiationId: number): Observable<Negotiation[]> {
    const url = this.suggestedNegotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    return this.http.get<Negotiation[]>(url).pipe(map(negotiation => {
      return plainToInstance(Negotiation, negotiation);
    }));
  }

  public watchLinkedNegotiation(companyId: number, negotiationId: number): Observable<Negotiation[]> {
    return this.pusherService.listen('company_' + companyId, 'linked_negotiation').pipe(
      startWith({}),
      mergeMap(negotiation => {
        return this.getLinkedNegotiation(companyId, negotiationId);
      })
    );
  }

  public getLinkedNegotiation(companyId: number, negotiationId: number): Observable<Negotiation[]> {
    const url = this.linkNegotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    return this.http.get<Negotiation[]>(url).pipe(
      map(negotiation => {
        return plainToInstance(Negotiation, negotiation);
      })
    );
  }

  public unlinkNegotiation(companyId: number, negotiationId: number, negotiations: number[]): Observable<any> {
    const url = this.linkNegotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    const body = { negotiations_to_unlink: negotiations };

    return this.http.put(url, body);
  }

  public linkNegotiation(companyId: number, negotiationId: number, negotiations: number[]): Observable<any> {
    const url = this.linkNegotiationsUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    const body = { negotiations_to_link: negotiations };

    return this.http.post(url, body);
  }

  public negotiationsToXSLS(companyId: number, filters?: any): Observable<any> {
    let url = this.negotiationsExportUrl.replace(":companyId", companyId + '');
    url = buildFilters(url, filters, this.queryMap);

    return this.http.get(url, { responseType: "blob" });
  }

  /**
   * Get Contracts that meet all these conditions:
   * - Same Product
   * - Same buyer Company (only if buyer is !this.buyer.activity.broker)
   * - Same seller Company (only if buyer is !this.seller.activity.broker)
   * - Is not linked to any Negotiation
   */
  public relatedContracts(companyId: number, negotiation: Negotiation): Observable<Contract[]> {
    const url = this.negotiationRelatedContractsPath
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiation.id + '');

    return this.http.get<Contract[]>(url).pipe(map(response => {
      return plainToInstance(Contract, response);
    }));
  }

  /**
   * Edits the Negotiation
   * [[Negotiation.original_booking_date|original booking date]].
   */
  public editBookingDate(companyId: number, negotiationId: number, date: Date): Observable<Negotiation> {
    const url = this.bookingDateUrl
      .replace(':companyId', companyId + '')
      .replace(':negotiationId', negotiationId + '');

    const body = { original_booking_date: date };

    return this.http.put<Negotiation>(url, body).pipe(map(response => {
      return plainToInstance(Negotiation, response);
    }));
  }
}
