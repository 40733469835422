import { Type } from "class-transformer";

import { Negotiation } from "../company/modules/commercial/models/negotiation.model";
import { Waybill } from "../company/modules/imported-data/models/waybill.model";
import { Company } from "./company.model";
import { Product } from "./product.model";
import { Destination } from "./slots-batch.model";

/**
 * A Slot is a reception and unloading space reserved for a specific day for a
 * truck with a given [[Product|load]].
 *
 * Ports, terminals and plants are able to receive and handle limited numbers
 * of trucks within a specific time frame. The slot booking is a critical
 * process to make the use of physical facilities more efficient.
 *
 * Slots are assigned and booked in [[SlotsBatch|batches]].
 *
 * ### Related UI components:
 * - [[SlotsComponent]]
 */
export class Slot {
  /** Agree's internal unique identifier. */
  readonly id: number;
  /**
   * Alphanumeric code generated by the [[SlotsBatch.recipient|recipient]].
   */
  reference: string;

  waybill?: Waybill;

  /**
   * Holder of the [[Waybill]]. It's the [[Company]] that will load the truck.
   */
  holder?: Company;

  /** Data collected from [[https://www.ssta.gob.ar/turnos|STOP]]. */
  @Type(() => Stop)
  stop?: Stop;

  unloaded?: boolean;

  constructor(data: Partial<Slot> = {}) {
    this.waybill = new Waybill();
    this.holder = new Company();

    Object.assign(this, data);
  }
}

/**
 * Information obtained through the
 * [[https://www.ssta.gob.ar/turnos/Login|slot system]] of the Argentine
 * Ministry of Transportation (STOP).
 */
export class Stop {
  /**
   * | id | Description |
   * |---:|-------------|
   * |  1 | Without CTG |
   * |  2 | Activated   |
   * |  3 | Unloaded    |
   * |  5 | Arrived     |
   */
  status: {
    id: number,
    name: string
  };

  @Type(() => Date)
  activated_at: Date;

  @Type(() => Date)
  arrived_at: Date;

  @Type(() => Date)
  unloaded_at: Date;

  @Type(() => Date)
  updated_at?: Date;

  /** Raw STOP data. */
  source?: any;
}

export class SlotDetails extends Slot {
  // The following data is from the first [[SlotsBatch]].
  // [[SlotsBatch.parent_id]] == null

  @Type(() => Date)
  date?: Date;
  /** All the [[Company|Companies]] involved in the Waybill. */
  actors?: {
    // holder: Company, // Will be defined in the last batch child
    intermediary?: Company,
    commercial_sender?: Company,
    buying_broker?: Company,
    term_market?: Company,
    selling_broker?: Company,
    representative?: Company,
    receiver?: Company,
    destination?: Company,
    carrier_broker?: Company,
    carrier?: Company,
    driver?: Company
  };
  destination?: Destination;
  product?: Product;

  // The following data is from my [[SlotsBatch]].
  // [[SlotsBatch.company]] == My Company
  // Or [[SlotsBatch]] that I'm able to see for actor (productor de seguors)

  negotiation?: Negotiation;
  allocator?: Company;

  assigned_to?: Company; // De donde obtengo esto?
}

export class STOPslot {
  idCupoTerminal: string;

  @Type(() => Date)
  fecha: Date;
  idTurnoDetalle?: any;
  turnoDetalle?: any;
  codGrano: number;
  grano: string;
  idTerminal: number;
  terminalDestino: string;
  codLocalidadDestino: number;
  localidadDestino: string;
  provinciaDestino: string;
  ctg?: string;
  cartaPorte?: string;

  /**
   * | ID | Status     | Description                                                                                                                                |
   * |---:|------------|--------------------------------------------------------------------------------------------------------------------------------------------|
   * |  1 | SIN_CTG    | Antes de ser activado, y para el caso de sea ANULADO vuelve a no tener CTG y puede ser modificado por las terminales o activado nuevamente |
   * |  2 | ACTIVADO   |                                                                                                                                            |
   * |  3 | DESCARGADO | Cierra el turno                                                                                                                            |
   * |  4 | BORRADO    | Se borra lógicamente del sistema y no existe más a los fines prácticos                                                                     |
   * |  5 | ARRIBADO   | Título informativo                                                                                                                         |
   * |  6 | RECHAZADO  | Este estado queda reflejado solo en el historial dado que el turno queda en estado SIN_CTG                                                 |
   */
  idCupoEstado: number;
  cupoEstado: string;
  idEstadoEnPlanta: number;
  estadoEnPlanta: string;

  @Type(() => Date)
  fechaActivado?: Date;

  @Type(() => Date)
  fechaArribado?: Date;

  @Type(() => Date)
  fechaDescargado?: Date;
  cosecha?: string;
  renspa?: string;
  nroEstablecimientoOrigen?: any;
  nroContrato?: any;
  nroPlantaRuca: number;
  pesoNetoEstimado?: number;
  kmRecorrer?: number;
  validaKM: string;
  cantHorasSalidaCamion?: number;
  dominio?: string;
  dominio_1?: string;
  dominio_2?: string;
  token?: string;
  className: string;
}

export class StopStatus{
  readonly id: number;
  name: string;
}
