import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Account } from '../auth/models/account.model';
import { SlotsRequest } from '../company/models/slots-request.model';
import { Negotiation } from '../company/modules/commercial/models/negotiation.model';
import { Contract } from '../company/modules/contracts/models/contract.model';
import { STOPslot, SlotDetails, StopStatus } from '../models/slot.model';
import { CPEActors, SlotHistory, SlotsBatch } from '../models/slots-batch.model';
import { buildFilters } from '../utilities/filters';
import { simpleHash } from '../utilities/json-tools';
import { PusherService } from './pusher.service';
import { startDay } from '../utilities/date';

@Injectable({
  providedIn: 'root'
})
export class SlotService {

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private baseUrl = '/:apiBase/companies/:companyId';
  private batchesUrl = '/:apiBatches/companies/:companyId';
  private slotsPath = this.baseUrl + '/slots';
  private slotsExportPath = this.batchesUrl + '/slots/export';
  private slotByIdPath = this.slotsPath + '/:slotId';
  private batchesPath = this.baseUrl + '/batches';
  private batchByIdPath = this.batchesPath + '/:batchId';
  private batchNegotiationPath = this.batchByIdPath + '/negotiation/:negotiationId';
  private batchContractPath = this.batchByIdPath + '/contract/:contractId';
  private batchTakePath = this.batchByIdPath + '/take';
  private CPEActorsPath = this.batchByIdPath + '/cpe-actors';
  private misTurnosActivosSimple = this.baseUrl + '/stop/misturnosactivossimple/:from/:to';
  private refreshPath = this.baseUrl + '/stop/sync'
  private slotsStats = this.baseUrl + '/slot-stats';
  private verifyBatchesExistencePath = this.batchesPath + '/exists';
  private stopStatusPath = '/:apiBase/v1/companies/:companyId/stop/status';

  private _collectionSubjects: { [eventKey: string]: BehaviorSubject<{ body: SlotsBatch[]; headers: HttpHeaders }> } = {};
  private _itemSubjects: { [batchId: number]: BehaviorSubject<SlotsBatch> } = {};
  private _sCollectionSubjects: { [eventKey: string]: BehaviorSubject<{ body: SlotDetails[]; headers: HttpHeaders }> } = {};
  private _stopStatuses: StopStatus[];
  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product][id]',
    'validity': 'filters[validity]',
    // FAS-2757
    'allocator_name': 'filters[allocator_name]',
    'recipient_name': 'filters[recipient_name]',
    'assigned_to': 'filters[assigned_to]',
    'sustainable': 'filters[sustainable]',
    'date_issued': 'filters[date_issued]',
    'zone_id': 'filters[zone_id]',
    'destination_id': 'filters[destination_id]',
    'zone_name': 'filters[zone_name]',
  };

  public create(companyId: number, account: Account, slotsBatches: SlotsBatch[], slotRequest: SlotsRequest = null): Observable<any> {
    const url = this.batchesPath
      .replace(':companyId', companyId + '');

    const data = {
      batches: slotsBatches,
      account: account,
      /**
       * is_front => This hotfix prevents the creation of batches from other 
       * sides than the agreed ones, e.g. integrations or external API calls.
       */
      is_front: true
    };

    if (slotRequest) {
      data["slot_request"] = slotRequest;
    }
    return this.http.post(url, data);
  }

  public getHistory(companyId: number, slotId: number): Observable<SlotHistory[]> {
    const url = this.slotByIdPath
      .replace(':companyId', companyId + '')
      .replace(':slotId', slotId + '');

    return this.http.get<SlotHistory[]>(url).pipe(map(response => {
      return plainToInstance(SlotHistory, response);
    }));
  }

  private getBatches(companyId: number, filters?: any, paginated?: boolean): Observable<{ body: SlotsBatch[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.batchesPath
      .replace(':companyId', companyId + '');

    url = buildFilters(url, filters, this.queryMap, ['validity']);

    return this.http.get<SlotsBatch[]>(url, { observe: 'response' }).pipe(map(response => {
      return { body: plainToInstance(SlotsBatch, response.body), headers: response.headers };
    }));
  }

  private get(companyId: number, batchId: number): Observable<SlotsBatch> {
    const url = this.batchByIdPath
      .replace(':companyId', companyId + '')
      .replace(':batchId', batchId + '');

    return this.http.get<SlotsBatch>(url).pipe(map(response => {
      return plainToInstance(SlotsBatch, response);
    }));
  }

  private getSlots(companyId: number, filters?: any, paginated?: boolean): Observable<{ body: SlotDetails[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    // https://agreemarket.atlassian.net/browse/FAS-2798
    let url = this.slotsPath
      .replace(':companyId', companyId + '');

    url = buildFilters(url, filters, this.queryMap, ['validity']);

    return this.http.get<SlotDetails[]>(url, { observe: 'response' }).pipe(map(response => {
      return { body: plainToInstance(SlotDetails, response.body), headers: response.headers };
    }));
  }

  public update(companyId: number, account: Account, batchId: number, status: any, slotsBatch?: SlotsBatch): Observable<any> {
    const url = this.batchByIdPath
      .replace(':companyId', companyId + '')
      .replace(':batchId', batchId + '');

    const data = {
      'status': status,
      'account': account
    };

    switch (status.id) {
      case 5:
        data['batch_returned'] = slotsBatch;
        break;
      default:
        data['batch_rejected'] = slotsBatch;
        break;
    }

    return this.http.post(url, data);
  }

  public deleteSlots(companyId: number, slotIds: number[]): Observable<any> {
    const url = this.slotsPath
      .replace(':companyId', companyId + '');

    // Params Object
    const params = { ids: JSON.stringify(slotIds) };

    return this.http.delete<any>(url, { params: params });
  }

  /**
   * Accepts and assigns the entire [[SlotsBatch]] to the specified
   * [[Company]].
   */
  public take(companyId: number, account: Account, batchId: number): Observable<SlotsBatch> {
    const url = this.batchTakePath
      .replace(':companyId', companyId + '')
      .replace(':batchId', batchId + '');

    const data = {
      'account': account
    };

    return this.http.post<SlotsBatch>(url, data).pipe(map(response => {
      return plainToInstance(SlotsBatch, response);
    }));
  }

  public watch(companyId: number, batchId: number): Observable<SlotsBatch> {
    return this.pusherService.subjectManager(
      {
        collection: this._itemSubjects,
        key: batchId,
        getData: () => this.get(companyId, batchId)
      },
      {
        channel: `company_${companyId}`,
        event: 'batch',
        dueTime: 2000,
        condition: (event: any) => event?.id === batchId
      });
  }

  public watchBatches(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: SlotsBatch[], headers: HttpHeaders }> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: eventKey,
        getData: () => this.getBatches(companyId, filters, paginated)
      },
      {
        channel: `company_${companyId}`,
        event: 'batch',
        dueTime: 2000
      }
    );
  }

  public watchSlots(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: SlotDetails[], headers: HttpHeaders }> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._sCollectionSubjects,
        key: eventKey,
        getData: () => this.getSlots(companyId, filters, paginated)
      },
      {
        channel: `company_${companyId}`,
        event: 'batch',
        dueTime: 2000
      }
    );
  }

  public updateNegotiation(companyId: number, slotsBatch: SlotsBatch, negotiation: Negotiation): Observable<SlotsBatch> {
    const url = this.batchNegotiationPath
      .replace(':companyId', companyId + '')
      .replace(':batchId', slotsBatch.id + '')
      .replace(':negotiationId', negotiation.id + '');

    return this.http.post<SlotsBatch>(url, {}).pipe(map(response => {
      return plainToInstance(SlotsBatch, response);
    }));
  }

  public updateContract(companyId: number, slotsBatch: SlotsBatch, contract: Contract): Observable<SlotsBatch> {
    const url = this.batchContractPath
      .replace(':companyId', companyId + '')
      .replace(':batchId', slotsBatch.id + '')
      .replace(':contractId', contract.id + '');

    return this.http.post<SlotsBatch>(url, {}).pipe(map(response => {
      return plainToInstance(SlotsBatch, response);
    }));
  }

  public deleteNegotiation(companyId: number, slotsBatch: SlotsBatch): Observable<SlotsBatch> {
    const url = this.batchNegotiationPath
      .replace(':companyId', companyId + '')
      .replace(':batchId', slotsBatch.id + '')
      .replace(':negotiationId', slotsBatch.negotiation.id + '');

    return this.http.delete<SlotsBatch>(url).pipe(map(response => {
      return plainToInstance(SlotsBatch, response);
    }));
  }

  public deleteContract(companyId: number, slotsBatch: SlotsBatch): Observable<SlotsBatch> {
    const url = this.batchContractPath
      .replace(':companyId', companyId + '')
      .replace(':batchId', slotsBatch.id + '')
      .replace(':contractId', slotsBatch.contract.id + '');

    return this.http.delete<SlotsBatch>(url).pipe(map(response => {
      return plainToInstance(SlotsBatch, response);
    }));
  }

  public export(companyId: number, range: Date[], filters: any = {}): Observable<any> {
    if (range && range.length === 2) {
      let url = this.slotsExportPath
        .replace(':companyId', companyId + '');

      if ('validity' in filters) {
        delete filters['validity'];
      }

      const from = startDay(new Date(range[0])); // start of the day
      const to = new Date(range[1]);

      to.setHours(23, 59, 59, 999); // end of the day

      filters['filters[date]'] = 'between:' + from.toISOString() + ',' + to.toISOString();

      url = buildFilters(url, filters, this.queryMap);

      return this.http.get(url);
    }
  }

  public getActors(companyId: number, batchId: number): Observable<CPEActors> {
    const url = this.CPEActorsPath
      .replace(':companyId', companyId + '')
      .replace(':batchId', batchId + '');

    return this.http.get<CPEActors>(url).pipe(map(response => {
      return plainToInstance(CPEActors, response);
    }));
  }

  public getSTOPSlots(companyId: number, from: Date, to: Date): Observable<STOPslot[]> {
    const url = this.misTurnosActivosSimple
      .replace(':companyId', companyId + '')
      .replace(':from', from.toISOString().split('T')[0])
      .replace(':to', to.toISOString().split('T')[0]);

    const stream = this.http.get<{
      count: number;
      data: STOPslot[];
      isError: boolean;
      ticks: number;
    }>(url);

    return stream.pipe(map(response => {
      return plainToInstance(STOPslot, response.data);
    }));
  }

  public getSats(companyId: number, filters?: any): Observable<{ body: any, headers: HttpHeaders }> {
    let url = this.slotsStats
      .replace(':companyId', companyId + '');

    url = buildFilters(url, filters, this.queryMap);

    return this.http.get<any>(url, { observe: 'response' });
  }

  public refreshSTOPstatus(companyId: number): Observable<number> {
    const url = this.refreshPath.replace(':companyId', companyId + '');

    return this.http.post<{ minutes_left: number }>(url, {}).pipe(map(response => {
      return response.minutes_left || 0.25;
    }));
  }

  public verifyBatchesExistence(companyId: number, account: Account, slotsBatches: SlotsBatch[]): Observable<SlotsBatch[]> {
    const url = this.verifyBatchesExistencePath
      .replace(':companyId', companyId + '');

    const data = {
      batches: slotsBatches,
      account: account
    };

    return this.http.post<SlotsBatch[]>(url, data).pipe(map(response => {
      return plainToInstance(SlotsBatch, response);
    }));
  }

  /**
    * Retrieves and caches the STOP statuses for a given company ID.
    *
    * @param {number} companyId - The ID of the company.
    * @returns {Observable<StopStatus[]>} - An observable of the STOP statuses.
    */
  public getSTOPStatuses(companyId: number): Observable<StopStatus[]> {
    if (this._stopStatuses) {
      return of(this._stopStatuses);
    }

    // TODO: companyId is irrelevant, STOP statuses are cross-company.
    const url = this.stopStatusPath.replace(':companyId', companyId + '');

    return this.http.get<StopStatus[]>(url).pipe(map(response => {
      // Cache response
      this._stopStatuses = plainToInstance(StopStatus, response);
      return this._stopStatuses;
    }));
  }
}

