import { forwardRef } from '@angular/core';
import { Type } from "class-transformer";
import { ArbitrationChamber } from '../../../../models/arbitration-chamber.model';
import { Media } from '../../../../models/media.model';
import { PaymentDetail } from '../../../../models/payment-detail.model';
import { Destination } from '../../../../models/slots-batch.model';
import { geolocationString, locationString } from '../../../../ui/pipes/location-string.pipe';
import { BusinessDetail } from './business-detail.model';

class Negotiability {
  product_detail: any;
  payment_detail: any;
  contract_detail: any;

  business_detail: {
    [key: string]: any;
  };

  constructor(data: Partial<Negotiability> = {}) {
    this.product_detail = {};
    this.payment_detail = {};
    this.contract_detail = {};
    this.business_detail = {
      delivery: {}
    };

    Object.assign(this, data);
  }
}

/**
 * A Proposal has all the terms and conditions of an specific operation.
 *
 * During the [[Negotiation]] of an [[Order]], multiple sibling Proposals will
 * be generated reflecting the different changes along the process. The last
 * Proposal reflects the current terms.
 *
 * ### Related UI components:
 * - [[ProposalDetailComponent|proposal-detail]]
 */
export class Proposal {

  @Type(forwardRef(() => PaymentDetail) as any)
  payment_detail: PaymentDetail;

  product_detail: {
    quality: any
  };

  @Type(forwardRef(() => BusinessDetail) as any)
  business_detail: BusinessDetail;

  arbitration_chamber: ArbitrationChamber;

  contract_detail: any[];
  language: string;
  general_observations: string;
  media: Media[];
  new_media: FileList;
  /**
   * Specify which terms of the proposal are subject to negotiation and which
   * are not.
   *
   * ### Sample value
   * ``` json
   * {
   *   "payment_detail": [],
   *   "product_detail": [],
   *   "business_detail": {
   *     "price": false,
   *     "delivery": [],
   *     "quantity": false
   *   },
   *   "contract_detail": [],
   *   "general_observations": false
   * }
   * ```
   */
  @Type(() => Negotiability)
  negotiability: Negotiability;

  quality_string: string;

  destination?: Destination;

  /** Commission percentage. */
  commission?: number;

  /** It's own production (primary). */
  own_production?: boolean;

  constructor(
  ) {
    this.payment_detail = new PaymentDetail();
    this.product_detail = {
      quality: {
        /*
        grade: null,
        optional: null,
        camera_condition: null
         */
      }
    };

    this.contract_detail = [];;
    this.business_detail = new BusinessDetail();

    this.negotiability = new Negotiability();
  }

  get getDate(): string {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1; //January is 0!
    const yyyy = today.getFullYear();

    return (dd < 10 ? '0' + dd : dd) + '/' + (mm < 10 ? '0' + mm : mm) + '/' + yyyy;
  }

  get locationString(): string {
    const locations = this.business_detail.delivery.locations,
      geolocations = this.business_detail.delivery.geolocations;

    if (locations && locations.length) {
      return locationString(locations);
    } else if (geolocations && geolocations.length) {
      return geolocationString(geolocations);
    } else '';
  }

  get hasObservations(): boolean {
    return this.general_observations ? true : false;
  }

  /** Used for sorting purposes. */
  get deliveryType(): string {
    return this.business_detail.delivery.delivery_type ? this.business_detail.delivery.delivery_type.name : ' ';
  }

  /**
   * Check all required fields.
   *
   * There is probably a way to optimize this.
   */
  get valid(): boolean {
    return this.business_detail.price.value != undefined &&
      this.business_detail.quantity.value != undefined &&
      Boolean(this.business_detail.harvest) &&
      this.business_detail.delivery.range != null &&
      this.business_detail.delivery.locations.length > 0;
  }
}
