import { forwardRef } from "@angular/core";
import { Type } from "class-transformer";

import { Company } from "../../../../models/company.model";
import { Currency } from "../../../../models/currency.model";
import { CustomStatus } from "../../../../models/custom-status.model";
import { DeliveryDetail } from "../../../../models/delivery-detail.model";
import { Label } from "../../../../models/label.model";
import { Location } from '../../../../models/location.model';
import { Price } from "../../../../models/price.model";
import { Product } from "../../../../models/product.model";
import { PTBFConditions } from "../../../../models/ptbf-conditions.model";
import { Quantity } from "../../../../models/quantity.model";
import { SlotsBatch } from "../../../../models/slots-batch.model";
import { Unit } from "../../../../models/unit.model";
import { SlotsRequest } from "../../../models/slots-request.model";
import { Negotiation } from "../../commercial/models/negotiation.model";
import { Fixation } from "../../imported-data/models/fixation.model";

export class ContractSummary {
  /**
   * If the Contract is fully invoiced
   * @deprecated
  */
  invoiced: boolean;
  /**
   * Total invoiced balance
   * @deprecated
  */
  balance: Price;
  /** Total volume applied */
  applied_volume: Quantity;
  /** Total volume with fixed price. PTBF Contracts only. */
  fixed_volume?: Quantity;
  liquidated_volume?: number;
  applied?: Quantity;
}

/**
 * Represents a real-life Contract document.
 *
 * ### Related UI components:
 * - [[ContractsTableComponent]]
 * - [[ContractLinkerComponent]]
 * - [[ContractModalComponent]]
 */
export class Contract {
  /** Agree's internal unique identifier. */
  readonly id: number;

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  @Type(() => Date)
  date: Date;

  @Type(() => Company)
  buyer: Company;

  @Type(() => Company)
  seller: Company;

  @Type(() => Company)
  brokers?: Company[];

  @Type(() => DeliveryDetail)
  delivery: DeliveryDetail;

  @Type(() => Location)
  origin: Location;

  @Type(() => Price)
  price: Price;

  @Type(() => Product)
  product: Product;

  @Type(() => Quantity)
  quantity: Quantity;

  /**
   * Conditions for price to be fixed (PTBF) Contracts.
   */
  @Type(() => PTBFConditions)
  fixing_conditions?: PTBFConditions[];

  /**
  * Fixations for price to be fixed (PTBF) Contracts.
  */
  @Type(() => Fixation)
  fixations?: Fixation[] = [];

  partial_payment: number;

  /**
   * @deprecated
   * The Lambda requires optimization.
   */
  @Type(() => CustomStatus)
  custom_status?: CustomStatus;

  /** Crop campaign period sow/harvest in "yy/yy" format (ex: 20/21). */
  crop: string;

  /**
   * Related [[Negotiation]]. ID property only.
   */
  @Type(() => Negotiation)
  negotiation?: any;

  @Type(() => Contract)
  linked_contracts?: Contract[];

  contract_type: {
    id: number,
    tag: string,
    description: string
  };
  /**
   * Properties that differ from the related [[Negotiation]].
   */
  differences?: string[];

  /**
   * Potential inconsistencies in imported data.
   */
  readonly warnings?: string[];

  /**
   * This reference is used as the Contract ID to show on screen.
   */
  reference: string;

  private _fixations_data: any;

  readonly bonus?: number;

  /** Returns fixations sumary information */
  get fixations_data(): {
    percentage: number;
    /** Fixation balance */
    balance: Price;
    /** Weighted average of fixed price */
    average_price: Price;
    /** Total fixation period */
    period: {
      from: Date;
      to: Date;
    };
  } {
    if (!this._fixations_data) {
      let balance: Price = new Price(),
        averagePrice: Price = new Price(),
        sameQuantityUnit = true,
        sameCurrency = true,
        from: Date[] = [],
        to: Date[] = [];

      const fixCheck = (fix: Fixation) => {
        // It is necessary that there is price and volume in all fixations
        const ok = Boolean(fix.price && fix.volume);
        if (!ok) {
          sameQuantityUnit = false;
          sameCurrency = false;
        }
        return ok;
      };

      if (this.fixations &&
        this.fixations.length) {

        const firstFixation = this.fixations[0];

        if (fixCheck(firstFixation)) {
          balance.unit = firstFixation.price.unit;
          averagePrice.unit = firstFixation.price.unit;
          averagePrice.quantity_unit = firstFixation.volume.unit;

          balance.value = 0;

          this.fixations.forEach((fixation, index) => {
            if (fixCheck(fixation)) {
              balance.value += fixation.volume.value * fixation.price.value;

              if (index > 0) {
                if (sameQuantityUnit && averagePrice.quantity_unit.id &&
                  fixation.volume.unit.id !== averagePrice.quantity_unit.id) sameQuantityUnit = false;
                if (sameCurrency && balance.unit.id &&
                  fixation.price.unit.id !== balance.unit.id) sameCurrency = false;
              }
            }
          });
        }

        if (this.summary.fixed_volume) averagePrice.value = balance.value / this.summary.fixed_volume.value;

        if (!sameQuantityUnit) {
          averagePrice = undefined;
        }

        if (!sameCurrency) {
          averagePrice = undefined;
          balance = undefined;
        }
      }

      if (this.fixing_conditions) {
        this.fixing_conditions.forEach(condition => {
          if (condition.from) from.push(condition.from);
          if (condition.to) to.push(condition.to);
        });
      }

      this._fixations_data = {
        percentage: (this.summary.fixed_volume && this.quantity.value) ? this.summary.fixed_volume.value / this.quantity.value : 0,
        balance: balance,
        average_price: averagePrice,
        period: {
          from: from.length ? new Date(Math.min.apply(null, from)) : undefined, // Gets lower from
          to: to.length ? new Date(Math.max.apply(null, to)) : undefined // Gets greater to
        }
      };
    }

    return this._fixations_data;
  }

  public reset(): void {
    this._fixations_data = undefined;
  }

  /**
   * Consolidated data
   */
  summary?: ContractSummary;

  /** For labeleable entities. */
  get entity(): string {
    return 'contract';
  }
  readonly labels?: Label[];

  @Type(() => Quantity)
  readonly applied: Quantity;

  public getBroker(companyId: number): Company {
    return this.brokers.find(broker => broker.id === companyId);
  }

  /**
   * Related [[SlotsBatch]].
   */
  @Type(() => SlotsBatch)
  readonly batches?: SlotsBatch[];

  @Type(forwardRef(() => () => SlotsBatch) as any)
  slot_request?: SlotsRequest[];

  /**
 * Quantity of slots assigned for this [[Contract]] .
 * @returns The total number of slots assigned in the [[Contract]].
 */
  get assignedQuantity(): number {
    const batchesFiltered = this.batches.filter(batch => batch.status.id === 1 || batch.status.id === 2);
    let total = 0;
    batchesFiltered.forEach(batch => {
      total += batch.slots_total
    });

    return total;
  }

  get assignableSlots(): number {
    const pendingVolumeToApply = this.pendingVolumeToApply();
    return Math.ceil(pendingVolumeToApply / 30) + 1;
  }

  private pendingVolumeToApply(): number {
    const negotiationVolume = this.quantity.value;
    const pendingVolume = negotiationVolume - this.appliedVolume.value;
    return pendingVolume;
  }

  get appliedVolume(): Quantity {
    if (this.summary && this.summary.applied_volume)
      return this.summary.applied_volume;
    return new Quantity({ value: 0 });
  }

  constructor(data: Partial<Contract> = {}) {
    this.price = new Price();
    this.quantity = new Quantity();
    this.delivery = new DeliveryDetail();

    Object.assign(this, data);
  }
}

class DataSummary {
  total: number; // Cantidad de Contratos
  volume: [ // Volumen total de los Contratos por unidad
    {
      unit: Unit;
      total: number; // Volumen en Contratos
      fixed: number; // Volumen de fijaciones
      applied: number; // Volumen aplicado a los Contratos
      invoiced: number; // Volumen facturado de los Contratos
      total_applied_without_invoice: number;
    }
  ];
  invoiced: number; // Cantidad de Contratos con liquidación final
  total_applied_without_invoice: number;
  balance: [ // Balance total de los Contratos por moneda
    {
      currency: Currency;
      value: number;
    }?
  ]; // Balance total de los Contratos
}

export class ContractStats {
  all: DataSummary;
  expired: DataSummary; // Contratos con fecha de entrega hasta < hoy
  to_expire: DataSummary; // Contratos con fecha de entrega hasta >= hoy y < hoy + 1 semana
  on_date: DataSummary; // Contratos con fecha de entrega hasta >= hoy + 1 semana
  buy: DataSummary; // Contratos como comprador
  sell: DataSummary; // Contratos como vendedor

  products: [ // Contratos por producto
    {
      id: number; // ID de producto
      name: string; // Nombre de producto
      all: DataSummary;
      buy: DataSummary; // Contratos como comprador
      sell: DataSummary; // Contratos como vendedor
    }?
  ];
}
