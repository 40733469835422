import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../auth/models/user.model';
import { LoginService } from '../auth/services/login.service';

declare var window: any;

/**
 * HubSpot Tracking Code API
 * 
 * In addition to tracking page views, the HubSpot tracking code allows you to
 * identify visitors, track events, and manually track page views (without
 * reloading the page). The Tracking Code API allows you to dynamically create
 * events and track event data in HubSpot.
 * 
 * https://developers.hubspot.com/docs/api/events/tracking-code
 */
@Injectable({
  providedIn: 'root'
})
export class HubSpotService {

  private scriptLoaded: boolean;
  private path: string;
  private user: User;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private loginService: LoginService
  ) {
    if (environment.hubspot) {
      // This object enables you to provide some configuration options to the widget before it initializes.
      window.hsConversationsSettings = {
        loadImmediately: false,
        // inlineEmbedSelector: '#some-id',
        // enableWidgetCookieBanner: true,
        // disableAttachment: true
      };

      this.loadScript(environment.hubspot.id);

      this.loginService.getCurrentUser().subscribe(user => {
        if (user) {
          this.loadChat();
          this.identify(user);
        }
      });
    }
  }

  private loadScript(hubspotId: string): void {
    if (!this.scriptLoaded) {
      let scriptElement = this.document.createElement('script');
      this.document.getElementsByTagName("head")[0].appendChild(scriptElement);

      scriptElement.addEventListener('load', () => {
        this.scriptLoaded = true;
      });

      scriptElement.type = "text/javascript";
      scriptElement.src = '//js.hs-scripts.com/' + hubspotId + '.js';
    }
  }

  /** Track a new page using setPath. */
  public setPath(path: string): void {
    path = path.split('?')[0];

    // Check if the path is different
    if (this.path !== path) {
      this.path = path;
      // Ignore queryparams
      // Update the path stored in the tracker:
      let _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', path]);
      // Track the page view for the new page
      if (this.scriptLoaded) _hsq.push(['trackPageView']);
    }
  }

  /**
   * This endpoint is used to identify a visitor to the site. The unique
   * identifier is an email address. If there is an existing contact record for
   * that email address, it will be updated. Otherwise, a new contact record
   * will be created. In both cases, the analytics data collected for the
   * visitor will be associated with the contact record.
   */
  private identify(user: User): void {
    // Check if the user is different
    if (!this.user || this.user.id !== user.id) {
      this.user = user;

      let _hsq = window._hsq = window._hsq || [];
      _hsq.push(['identify', {
        email: user.email,
        id: user.id
      }]);
    }
  }

  /**
   * Load the widget for the first time on the page. If the widget is currently
   * loaded, subsequent calls to this method are no-ops.
   * This method is only necessary if you set the loadImmediately flag to
   * false. Otherwise, the widget will load itself automatically.
   */
  private loadChat(): void {
    const onConversationsAPIReady = () => {
      const { widget } = window.HubSpotConversations;
      const status = widget.status();

      // window.HubSpotConversations.on('conversationStarted', payload => {
      //   console.log('conversationStarted', payload);
      // });

      // window.HubSpotConversations.on('conversationClosed', payload => {
      //   console.log('conversationClosed', payload);
      // });

      // window.HubSpotConversations.on('unreadConversationCountChanged', payload => {
      //   console.log('unreadConversationCountChanged', payload);
      // });

      if (!status.loaded) widget.load();
    };

    /* If external API methods are already available, use them. */
    if (window.HubSpotConversations) onConversationsAPIReady();
    else {
      /**
       * Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
       * These callbacks will be called once the external API has been initialized.
       */
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  }

  /**
   * Open the widget. If the widget is already open or isn't currently loaded, this is a no-op.
   */
  public open(): void {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else window.location.href = "mailto:info@agree.ag";
  }
}
